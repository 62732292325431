define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "REICyv+r",
    "block": "[[[11,1],[16,0,[29,[\"x-toggle-container\\n    \",[30,1],\"\\n    \",[52,[30,2],\"x-toggle-container-disabled\"],\"\\n    \",[52,[30,3],\"x-toggle-container-checked\"],\"\\n  \"]]],[17,4],[4,[38,1],null,[[\"onPanStart\",\"onPan\",\"pointerTypes\"],[[30,0,[\"handlePan\"]],[30,0,[\"handlePan\"]],[28,[37,2],[\"touch\",\"mouse\"],null]]]],[12],[1,\"\\n  \"],[11,\"input\"],[24,0,\"x-toggle\"],[16,\"checked\",[30,5]],[16,\"disabled\",[30,2]],[16,1,[30,6]],[16,3,[30,7]],[24,4,\"checkbox\"],[4,[38,3],[\"change\",[28,[37,4],[[30,0],[30,8]],[[\"value\"],[\"target.checked\"]]]],null],[12],[13],[1,\"\\n\\n  \"],[10,\"label\"],[15,\"for\",[30,0,[\"effectiveForId\"]]],[12],[1,\"\\n    \"],[10,0],[15,1,[29,[\"x-toggle-visual-\",[30,6]]]],[14,\"role\",\"checkbox\"],[15,0,[29,[\"x-toggle-btn\\n        \",[30,0,[\"themeClass\"]],\"\\n        \",[30,0,[\"variant\"]],\"\\n        \",[30,1],\"\\n        \",[52,[30,2],\" x-toggle-disabled\"],\"\\n      \"]]],[15,\"aria-owns\",[30,6]],[15,\"aria-checked\",[30,5]],[15,\"data-tg-on\",[30,9]],[15,\"data-tg-off\",[30,10]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@size\",\"@disabled\",\"@value\",\"&attrs\",\"@toggled\",\"@forId\",\"@name\",\"@sendToggle\",\"@onLabel\",\"@offLabel\"],false,[\"if\",\"did-pan\",\"array\",\"on\",\"action\"]]",
    "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs",
    "isStrictMode": false
  });
});