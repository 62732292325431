function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("pix-ui/stories/pix-select.stories", ["exports", "@ember/template-factory", "@storybook/addon-actions"], function (_exports, _templateFactory, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withLabel = _exports.searchableSelect = _exports.argTypes = _exports.Default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixSelect
              @id={{id}}
              @options={{options}}
              @onChange={{onChange}}
              @selectedOption={{selectedOption}}
              @emptyOptionLabel={{emptyOptionLabel}}
              @emptyOptionNotSelectable={{emptyOptionNotSelectable}}
              @isSearchable={{isSearchable}}
              @isValidationActive={{isValidationActive}}
              @label={{label}}
            />
          
      */
      {
        "id": "p8WyLKmz",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@options\",\"@onChange\",\"@selectedOption\",\"@emptyOptionLabel\",\"@emptyOptionNotSelectable\",\"@isSearchable\",\"@isValidationActive\",\"@label\"],[[99,1,[\"@id\"]],[99,2,[\"@options\"]],[99,3,[\"@onChange\"]],[99,4,[\"@selectedOption\"]],[99,5,[\"@emptyOptionLabel\"]],[99,6,[\"@emptyOptionNotSelectable\"]],[99,7,[\"@isSearchable\"]],[99,8,[\"@isValidationActive\"]],[99,9,[\"@label\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-select\",\"id\",\"options\",\"onChange\",\"selectedOption\",\"emptyOptionLabel\",\"emptyOptionNotSelectable\",\"isSearchable\",\"isValidationActive\",\"label\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    options: [{
      value: '1',
      label: 'Figues'
    }, {
      value: '2',
      label: 'Bananes'
    }, {
      value: '3',
      label: 'Noix'
    }, {
      value: '4',
      label: 'Papayes'
    }, {
      value: '5',
      label: 'Fèves de chocolat'
    }, {
      value: '6',
      label: 'Dattes'
    }, {
      value: '7',
      label: 'Mangues'
    }, {
      value: '8',
      label: 'Jujube'
    }, {
      value: '9',
      label: 'Avocat'
    }, {
      value: '10',
      label: 'Fraises'
    }, {
      value: '11',
      label: 'Kaki'
    }],
    onChange: (0, _addonActions.action)('onChange')
  };
  var withLabel = _exports.withLabel = Template.bind({});
  withLabel.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    id: 'pix-select-with-label',
    label: 'Ton fruit préféré: '
  });
  var searchableSelect = _exports.searchableSelect = Template.bind({});
  searchableSelect.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    emptyOptionNotSelectable: false,
    isSearchable: true,
    isValidationActive: true,
    placeholder: 'Fraises, Mangues...'
  });
  var argTypes = _exports.argTypes = {
    options: {
      name: 'options',
      description: 'Les options sont représentées par un tableau d‘objet contenant les propriétés ``value`` et ``label``. ``value`` doit être de type ``String`` pour être que le champ puisse être cherchable',
      type: {
        name: 'object',
        required: true
      }
    },
    onChange: {
      name: 'onChange',
      description: 'Fonction à appeler si une option est sélectionnée',
      type: {
        required: true
      },
      control: {
        disable: true
      }
    },
    selectedOption: {
      name: 'selectedOption',
      description: 'Option sélectionnée',
      options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      control: {
        type: 'select'
      },
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    emptyOptionLabel: {
      name: 'emptyOptionLabel',
      description: 'Texte à afficher si aucune option n‘est sélectionnée',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    isSearchable: {
      name: 'isSearchable',
      description: 'Rend le champ cherchable',
      control: {
        type: 'boolean'
      },
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    },
    isValidationActive: {
      name: 'isValidationActive',
      description: 'Rend la bordure du champ vert au focus si la valeur de recherche match une option (c\'est à dire si l\'utilisateur a selectionné une option valable',
      control: {
        type: 'boolean'
      },
      type: {
        name: "boolean",
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    },
    emptyOptionNotSelectable: {
      name: 'emptyOptionNotSelectable',
      description: 'Rend le premier champ qui est vide non visible une fois sélectionné',
      control: {
        type: 'boolean'
      },
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    },
    size: {
      name: 'size',
      description: '⚠️ **Propriété dépréciée** ⚠️ , désormais tous les éléments de formulaires feront 36px de hauteur.',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'small'
        }
      }
    },
    label: {
      name: 'label',
      description: 'Donne un label au champ, le paramètre @id devient obligatoire avec ce paramètre.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    }
  };
});