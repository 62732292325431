define("pix-ui/stories/pix-input-password.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withLabelAndInformation = _exports.withErrorMessage = _exports.argTypes = _exports.Template = _exports.Default = void 0;
  var Template = _exports.Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixInputPassword
              @ariaLabel={{ariaLabel}}
              @id={{id}}
              @label={{label}}
              @information={{information}}
              @errorMessage={{errorMessage}}
            />
          
      */
      {
        "id": "O/nIfBi1",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@ariaLabel\",\"@id\",\"@label\",\"@information\",\"@errorMessage\"],[[99,1,[\"@ariaLabel\"]],[99,2,[\"@id\"]],[99,3,[\"@label\"]],[99,4,[\"@information\"]],[99,5,[\"@errorMessage\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-input-password\",\"ariaLabel\",\"id\",\"label\",\"information\",\"errorMessage\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    id: 'firstName',
    ariaLabel: 'Mot de passe'
  };
  var withLabelAndInformation = _exports.withLabelAndInformation = Template.bind({});
  withLabelAndInformation.args = {
    id: 'password',
    label: 'Mot de passe',
    information: 'Une brève information'
  };
  var withErrorMessage = _exports.withErrorMessage = Template.bind({});
  withErrorMessage.args = {
    id: 'password',
    label: 'Mot de passe',
    errorMessage: 'Un message d\'erreur.'
  };
  var argTypes = _exports.argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: 'Valeur de l\'input',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    label: {
      name: 'label',
      description: 'Label de l\'input. Requis si ariaLabel n\'est pas définit.',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    ariaLabel: {
      name: 'ariaLabel',
      description: 'L\'action du bouton, pour l\'accessibilité. Requis si label n\'est pas définit.',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    information: {
      name: 'information',
      description: 'Un descriptif complétant le label',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    errorMessage: {
      name: 'errorMessage',
      description: 'Affiche le message d\'erreur donné et encadre en rouge le champ',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    }
  };
});