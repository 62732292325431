define("pix-ui/stories/pix-message.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withIcon = _exports.warning = _exports.success = _exports.error = _exports.argTypes = _exports.Default = void 0;
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixMessage
            @type={{type}}
            @withIcon={{withIcon}}
            >
              Ceci est un message {{type}} avec un texte tellement long qu'il est nécessaire <br /> de l'afficher sur deux lignes.
            </PixMessage>
          
      */
      {
        "id": "XJvneUMw",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@type\",\"@withIcon\"],[[99,1,[\"@type\"]],[99,2,[\"@withIcon\"]]]],[[\"default\"],[[[[1,\"\\n        Ceci est un message \"],[1,[34,1]],[1,\" avec un texte tellement long qu'il est nécessaire \"],[10,\"br\"],[12],[13],[1,\" de l'afficher sur deux lignes.\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-message\",\"type\",\"withIcon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  var error = _exports.error = Template.bind({});
  error.args = {
    type: 'error',
    withIcon: true
  };
  var warning = _exports.warning = Template.bind({});
  warning.args = {
    type: 'warning',
    withIcon: true
  };
  var success = _exports.success = Template.bind({});
  success.args = {
    type: 'success',
    withIcon: true
  };
  var withIcon = _exports.withIcon = Template.bind({});
  withIcon.args = {
    withIcon: true
  };
  var argTypes = _exports.argTypes = {
    type: {
      name: 'type',
      description: 'Type du message',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'info',
      control: {
        type: 'select',
        options: ['info', 'success', 'warning', 'alert', 'error']
      }
    },
    withIcon: {
      name: 'withIcon',
      description: 'Icône du message',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false,
      control: {
        type: 'boolean'
      }
    }
  };
});