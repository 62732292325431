define("ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "VKwYeClF",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@bubbles\",\"@onChange\"],[[30,0,[\"isSelected\"]],false,[28,[37,1],[[30,0,[\"doClickOnRow\"]],[30,0,[\"index\"]],[30,0,[\"record\"]]],null]]],null],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"paper-checkbox\",\"fn\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox.hbs",
    "isStrictMode": false
  });
});