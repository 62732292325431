define("ember-models-table/templates/components/models-table/themes/ember-paper/global-filter", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8z8I7DwS",
    "block": "[[[10,0],[14,0,\"globalSearch\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[16,0,[28,[37,4],[[30,0,[\"themeInstance\",\"input\"]],\" filterString\"],null]]],[[\"@label\",\"@placeholder\",\"@value\",\"@onChange\"],[[30,0,[\"themeInstance\",\"searchLabelMsg\"]],[30,0,[\"themeInstance\",\"searchPlaceholderMsg\"]],[30,0,[\"value\"]],[28,[37,5],[[28,[37,6],[[30,0,[\"value\"]]],null]],null]]],null],[1,\"\\n\"],[41,[30,0,[\"globalFilterUsed\"]],[[[1,\"      \"],[8,[39,7],[[16,0,[28,[37,4],[\"clearFilterIcon \",[30,0,[\"themeInstance\",\"clearFilterIcon\"]]],null]]],[[\"@onClick\",\"@iconButton\"],[[28,[37,5],[[30,0,[\"clearGlobalFilter\"]]],null],true]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,8],[\"close\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"paper-input\",\"concat\",\"fn\",\"mut\",\"paper-button\",\"paper-icon\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/global-filter.hbs",
    "isStrictMode": false
  });
});