define("ember-composable-helpers/helpers/toggle", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils"], function (_exports, _helper, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toggle = toggle;
  function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function nextIndex(length, currentIdx) {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }
    return currentIdx + 1;
  }
  function toggle(_ref) {
    var _ref2 = _toArray(_ref),
      prop = _ref2[0],
      obj = _ref2[1],
      values = _ref2.slice(2);
    return function () {
      var currentValue = (0, _object.get)(obj, prop);
      if ((0, _utils.isPresent)(values)) {
        var currentIdx = values.indexOf(currentValue);
        var nextIdx = nextIndex(values.length, currentIdx);
        return (0, _object.set)(obj, prop, values[nextIdx]);
      }
      return (0, _object.set)(obj, prop, !currentValue);
    };
  }
  var _default = _exports.default = (0, _helper.helper)(toggle);
});