define("ember-file-upload/system/data-transfer", ["exports", "@ember/array", "@ember/object", "ember-file-upload/system/trim"], function (_exports, _array, _object, _trim) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getDataSupport = {};
  var _default = _exports.default = _object.default.extend({
    dataTransfer: null,
    itemDetails: null,
    queue: null,
    getData: function getData(type) {
      var dataTransfer = (0, _object.get)(this, 'dataTransfer');
      if (getDataSupport[type] == null) {
        try {
          var data = dataTransfer.getData(type);
          getDataSupport[type] = true;
          return data;
        } catch (e) {
          getDataSupport[type] = false;
        }
      } else if (getDataSupport[type]) {
        return dataTransfer.getData(type);
      }
    },
    valid: (0, _object.computed)('dataTransfer.files', 'files', 'itemDetails', {
      get: function get() {
        if ((0, _object.get)(this, 'files') == null) {
          return true;
        }
        return ((0, _object.get)(this, 'dataTransfer.items.length') || (0, _object.get)(this, 'dataTransfer.files.length') || (0, _object.get)(this, 'itemDetails.length')) === (0, _object.get)(this, 'files.length');
      }
    }),
    files: (0, _object.computed)('queue.{accept,multiple}', 'dataTransfer', 'itemDetails', {
      get: function get() {
        var fileList = (0, _object.get)(this, 'dataTransfer.files');
        var itemList = (0, _object.get)(this, 'dataTransfer.items');
        var itemDetails = (0, _object.get)(this, 'itemDetails');
        if (fileList == null && itemList || itemList != null && fileList != null && itemList.length > fileList.length) {
          fileList = itemList;
        }
        if (fileList == null && itemDetails || itemDetails != null && fileList != null && itemDetails.length > fileList.length) {
          fileList = itemDetails;
        }
        if (fileList == null) {
          return null;
        }
        var files = (0, _array.A)();
        if (!(0, _object.get)(this, 'queue.multiple') && fileList.length > 1) {
          files.push(fileList[0]);
        } else {
          for (var i = 0, len = fileList.length; i < len; i++) {
            files.push(fileList[i]);
          }
        }
        var accept = (0, _object.get)(this, 'queue.accept');
        if (accept == null) {
          return files;
        }
        var tokens = (0, _array.A)(accept.split(',').map(function (token) {
          return (0, _trim.default)(token).toLowerCase();
        }));
        var extensions = (0, _array.A)(tokens.filter(function (token) {
          return token.indexOf('.') === 0;
        }));
        var mimeTypeTests = (0, _array.A)((0, _array.A)(tokens.filter(function (token) {
          return token.indexOf('.') !== 0;
        })).map(function (mimeType) {
          return function (type) {
            if ((0, _array.A)(['audio/*', 'video/*', 'image/*']).includes(mimeType)) {
              return type.split('/')[0] === mimeType.split('/')[0];
            } else {
              return type === mimeType;
            }
          };
        }));
        return files.filter(function (file) {
          var extension = null;
          if (file.name && /(\.[^.]+)$/.test(file.name)) {
            extension = file.name.toLowerCase().match(/(\.[^.]+)$/)[1];
          }
          var type = file.type.toLowerCase();
          return mimeTypeTests.find(function (mimeTypeTest) {
            return mimeTypeTest(type);
          }) || extensions.indexOf(extension) !== -1;
        });
      }
    })
  });
});