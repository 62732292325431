define("ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MUoQ2Q9e",
    "block": "[[[8,[39,0],null,[[\"@indeterminate\",\"@value\",\"@onChange\"],[[28,[37,1],[[30,0,[\"selectedItems\",\"length\"]],[28,[37,2],[[30,0,[\"selectedItems\",\"length\"]],[30,0,[\"data\",\"length\"]]],null]],null],[28,[37,3],[[30,0,[\"selectedItems\",\"length\"]],[30,0,[\"data\",\"length\"]]],null],[30,0,[\"doToggleAllSelection\"]]]],null],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"paper-checkbox\",\"and\",\"not-eq\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox.hbs",
    "isStrictMode": false
  });
});