function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("pix-ui/stories/pix-tooltip.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unescapeHtml = _exports.right = _exports.left = _exports.isWide = _exports.isLight = _exports.isInline = _exports.bottom = _exports.argTypes = _exports.Default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixTooltip
              @id="tooltip-1"
              @text={{this.text}}
              @position={{this.position}}
              @isLight={{this.isLight}}
              @isInline={{this.isInline}}
              @isWide={{this.isWide}}
              @unescapeHtml={{this.unescapeHtml}}
            >
              <PixButton aria-describedby="tooltip-1">
                {{this.label}}
              </PixButton>
            </PixTooltip>
          
      */
      {
        "id": "0o1Y+g5n",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@text\",\"@position\",\"@isLight\",\"@isInline\",\"@isWide\",\"@unescapeHtml\"],[\"tooltip-1\",[30,0,[\"text\"]],[30,0,[\"position\"]],[30,0,[\"isLight\"]],[30,0,[\"isInline\"]],[30,0,[\"isWide\"]],[30,0,[\"unescapeHtml\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],[[24,\"aria-describedby\",\"tooltip-1\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tooltip\",\"pix-button\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    text: 'Hello World',
    label: 'À survoler pour voir la tooltip'
  };
  var isLight = _exports.isLight = Template.bind({});
  isLight.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    isLight: true
  });
  var isWide = _exports.isWide = Template.bind({});
  isWide.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas molestie mauris vel viverra.',
    isWide: true
  });
  var isInline = _exports.isInline = Template.bind({});
  isInline.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    text: 'Je suis une trèèèèèèèès longue information',
    isInline: true
  });
  var left = _exports.left = Template.bind({});
  left.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Mon infobulle apparaît à gauche',
    position: 'left',
    isInline: true
  });
  var right = _exports.right = Template.bind({});
  right.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Mon infobulle apparaît à droite',
    position: 'right',
    isInline: true
  });
  var bottom = _exports.bottom = Template.bind({});
  bottom.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Mon infobulle apparaît en bas',
    position: 'bottom'
  });
  var unescapeHtml = _exports.unescapeHtml = Template.bind({});
  unescapeHtml.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    text: 'Hello <b style="color: red;">W</b>orld',
    label: 'J\'affiche du html',
    unescapeHtml: true
  });
  var argTypes = _exports.argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant permettant de référencer le déclencheur via aria-describedby',
      type: {
        name: 'string',
        required: true
      }
    },
    text: {
      name: 'text',
      defaultValue: 'Tooltiptop',
      description: 'Texte à afficher',
      type: {
        name: 'string',
        required: false
      }
    },
    position: {
      name: 'position',
      description: 'Position de la tooltip',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'top'
        }
      },
      control: {
        type: 'select',
        options: ['top', 'top-left', 'top-right', 'right', 'bottom', 'bottom-left', 'bottom-right', 'left']
      }
    },
    isLight: {
      name: 'isLight',
      description: 'Affichage en mode clair',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isInline: {
      name: 'isInline',
      description: 'Affichage en une seule ligne',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isWide: {
      name: 'isWide',
      description: 'Affichage large',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    unescapeHtml: {
      name: 'unescapeHtml',
      description: 'Évite d\'échapper les caractères HTML',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    }
  };
});