define("pix-ui/stories/pix-stars.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stars = _exports.argTypes = void 0;
  var stars = _exports.stars = function stars(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixStars
              @count={{count}}
              @total={{total}}
              @alt={{alt}}
              @color={{color}}
            />
          
      */
      {
        "id": "VTHFwcHZ",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@count\",\"@total\",\"@alt\",\"@color\"],[[99,1,[\"@count\"]],[99,2,[\"@total\"]],[99,3,[\"@alt\"]],[99,4,[\"@color\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-stars\",\"count\",\"total\",\"alt\",\"color\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var argTypes = _exports.argTypes = {
    count: {
      name: 'count',
      description: 'Nombre total d’étoiles actives',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 2
    },
    total: {
      name: 'total',
      description: 'Nombre total d’étoiles',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 5
    },
    alt: {
      name: 'alt',
      description: 'Message alternatif pour les étoiles',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Message'
    },
    color: {
      name: 'color',
      description: 'Couleur des étoiles',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'yellow',
      control: {
        type: 'select',
        options: ['yellow', 'blue', 'grey']
      }
    }
  };
});