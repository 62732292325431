function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("pix-ui/stories/pix-button.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.size = _exports.shape = _exports.loader = _exports.disabled = _exports.colors = _exports.borders = _exports.argsTypes = _exports.Default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
          <section style={{this.style}}>
            <PixButton
              @triggerAction={{action triggerAction}}
              @loadingColor={{loadingColor}}
              @shape={{shape}}
              @backgroundColor={{backgroundColor}}
              @isDisabled={{isDisabled}}
              @isLoading={{isLoading}}
              @size={{size}}
              @isBorderVisible={{isBorderVisible}}
            >
              {{this.label}}
            </PixButton>
          </section>
          {{#each extraButtons as |button|}}
            <section style={{button.style}}>
              <PixButton
                @triggerAction={{action triggerAction}}
                @loadingColor={{button.loadingColor}}
                @shape={{button.shape}}
                @backgroundColor={{button.backgroundColor}}
                @isDisabled={{button.isDisabled}}
                @isLoading={{button.isLoading}}
                @size={{button.size}}
                @isBorderVisible={{button.isBorderVisible}}
              >
                {{button.label}}
              </PixButton>
            </section>
          {{/each}}
      */
      {
        "id": "IEMkJ/Cl",
        "block": "[[[1,\"\\n    \"],[10,\"section\"],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@triggerAction\",\"@loadingColor\",\"@shape\",\"@backgroundColor\",\"@isDisabled\",\"@isLoading\",\"@size\",\"@isBorderVisible\"],[[28,[37,1],[[30,0],[33,2]],null],[99,3,[\"@loadingColor\"]],[99,4,[\"@shape\"]],[99,5,[\"@backgroundColor\"]],[99,6,[\"@isDisabled\"]],[99,7,[\"@isLoading\"]],[99,8,[\"@size\"]],[99,9,[\"@isBorderVisible\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,11],[[28,[37,11],[[33,12]],null]],null],null,[[[1,\"      \"],[10,\"section\"],[15,5,[30,1,[\"style\"]]],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@triggerAction\",\"@loadingColor\",\"@shape\",\"@backgroundColor\",\"@isDisabled\",\"@isLoading\",\"@size\",\"@isBorderVisible\"],[[28,[37,1],[[30,0],[33,2]],null],[30,1,[\"loadingColor\"]],[30,1,[\"shape\"]],[30,1,[\"backgroundColor\"]],[30,1,[\"isDisabled\"]],[30,1,[\"isLoading\"]],[30,1,[\"size\"]],[30,1,[\"isBorderVisible\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null]],[\"button\"],false,[\"pix-button\",\"action\",\"triggerAction\",\"loadingColor\",\"shape\",\"backgroundColor\",\"isDisabled\",\"isLoading\",\"size\",\"isBorderVisible\",\"each\",\"-track-array\",\"extraButtons\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    loadingColor: 'white',
    shape: 'squircle',
    size: 'big',
    backgroundColor: 'blue',
    label: 'Bouton',
    triggerAction: function triggerAction() {
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, 2000);
      });
    }
  };
  var borders = _exports.borders = Template.bind({});
  borders.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton avec bordure sur fond clair',
    backgroundColor: 'transparent-light',
    loadingColor: 'grey',
    isBorderVisible: true,
    extraButtons: [_objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec bordure sur fond sombre',
      style: 'background-color: #775555',
      backgroundColor: 'transparent-dark',
      isBorderVisible: true
    })]
  });
  var colors = _exports.colors = Template.bind({});
  colors.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton avec background blue (default)',
    extraButtons: [_objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background green',
      backgroundColor: 'green'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background yellow',
      backgroundColor: 'yellow'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background red',
      backgroundColor: 'red'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background grey',
      backgroundColor: 'grey'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec bordure sur fond clair',
      backgroundColor: 'transparent-light',
      loadingColor: 'grey',
      isBorderVisible: true
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec bordure sur fond sombre',
      style: 'background-color: #775555',
      backgroundColor: 'transparent-dark',
      isBorderVisible: true
    })]
  });
  var disabled = _exports.disabled = Template.bind({});
  disabled.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton désactivé',
    isDisabled: true
  });
  var loader = _exports.loader = Template.bind({});
  loader.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton avec loader au clic',
    backgroundColor: 'yellow',
    loadingColor: 'grey',
    extraButtons: [_objectSpread(_objectSpread({}, Default.args), {}, {
      triggerAction: function triggerAction() {},
      isLoading: true
    })]
  });
  var shape = _exports.shape = Template.bind({});
  shape.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton rounded',
    shape: 'rounded'
  });
  var size = _exports.size = Template.bind({});
  size.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton small',
    size: 'small'
  });
  var argsTypes = _exports.argsTypes = {
    type: {
      name: 'type',
      description: 'type du bouton',
      type: {
        required: false
      },
      control: {
        disable: true
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'button'
        }
      }
    },
    triggerAction: {
      name: 'triggerAction',
      description: 'fonction à appeler en cas de clic, optionnel si le bouton est de type submit',
      type: {
        required: true
      },
      control: {
        disable: true
      }
    },
    loadingColor: {
      name: 'loadingColor',
      description: 'couleur de chargement: `white`, `grey`',
      type: {
        name: 'string',
        required: false
      },
      options: ['white', 'grey'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'white'
        }
      }
    },
    shape: {
      name: 'shape',
      description: 'forme: `rounded`,`squircle`',
      type: {
        name: 'string',
        required: false
      },
      options: ['rounded', 'squircle'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'squircle'
        }
      }
    },
    backgroundColor: {
      name: 'backgroundColor',
      description: 'color: `blue`, `green`, `yellow`, `red`, `grey`, `transparent-light`, `transparent-dark`',
      options: ['blue', 'green', 'yellow', 'red', 'grey', 'transparent-light', 'transparent-dark'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'blue'
        }
      }
    },
    isDisabled: {
      name: 'isDisabled',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    isLoading: {
      name: 'isLoading',
      description: 'Affiche un loader. Si `@triggerAction` retourne une promesse alors le loading est géré automatiquement.',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    size: {
      name: 'size',
      description: 'taille: `big`,`small`',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    isBorderVisible: {
      name: 'isBorderVisible',
      description: 'Paramètre utilisé seulement quand le `backgroundColor` est `transparent-light` ou `transparent-dark`',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    route: {
      name: 'route',
      description: 'Déprécié et remplacé par le composant PixButtonLink',
      type: {
        name: 'string',
        required: false
      }
    },
    model: {
      name: 'model',
      description: 'Déprécié et remplacé par le composant PixButtonLink',
      type: {
        required: false
      }
    }
  };
});