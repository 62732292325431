define("ember-cli-notifications/services/notifications", ["exports", "@ember/service", "@ember/polyfills", "@ember/array", "@ember/object", "@ember/runloop", "ember-get-config"], function (_exports, _service, _polyfills, _array, _object, _runloop, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var notificationAssign = _polyfills.assign || _polyfills.merge;
  var globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object
  var _default = _exports.default = _service.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('content', (0, _array.A)());
    },
    // Method for adding a notification
    addNotification: function addNotification(options) {
      var _ref, _options$autoClear, _ref2, _options$clearDuratio;
      // If no message is set, throw an error
      if (!options.message) {
        throw new Error("No notification message set");
      }
      var notification = _object.default.create({
        message: options.message,
        type: options.type || 'info',
        autoClear: (_ref = (_options$autoClear = options.autoClear) !== null && _options$autoClear !== void 0 ? _options$autoClear : globals.autoClear) !== null && _ref !== void 0 ? _ref : false,
        clearDuration: (_ref2 = (_options$clearDuratio = options.clearDuration) !== null && _options$clearDuratio !== void 0 ? _options$clearDuratio : globals.clearDuration) !== null && _ref2 !== void 0 ? _ref2 : 3200,
        onClick: options.onClick,
        htmlContent: options.htmlContent || false,
        cssClasses: options.cssClasses
      });
      this.content.pushObject(notification);
      if (notification.autoClear) {
        notification.set('remaining', notification.get('clearDuration'));
        this.setupAutoClear(notification);
      }
      return notification;
    },
    // Helper methods for each type of notification
    error: function error(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'error'
      }, options));
    },
    success: function success(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'success'
      }, options));
    },
    info: function info(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'info'
      }, options));
    },
    warning: function warning(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'warning'
      }, options));
    },
    removeNotification: function removeNotification(notification) {
      var _this = this;
      if (!notification) {
        return;
      }
      notification.set('dismiss', true);

      // Delay removal from DOM for dismissal animation
      _runloop.run.later(this, function () {
        _this.content.removeObject(notification);
      }, 500);
    },
    setupAutoClear: function setupAutoClear(notification) {
      var _this2 = this;
      notification.set('startTime', Date.now());
      var timer = _runloop.run.later(this, function () {
        // Hasn't been closed manually
        if (_this2.content.indexOf(notification) >= 0) {
          _this2.removeNotification(notification);
        }
      }, notification.get('remaining'));
      notification.set('timer', timer);
    },
    pauseAutoClear: function pauseAutoClear(notification) {
      _runloop.run.cancel(notification.get('timer'));
      var elapsed = Date.now() - notification.get('startTime');
      var remaining = notification.get('clearDuration') - elapsed;
      notification.set('remaining', remaining);
    },
    clearAll: function clearAll() {
      var _this3 = this;
      this.get('content').forEach(function (notification) {
        _this3.removeNotification(notification);
      });
      return this;
    },
    setDefaultAutoClear: function setDefaultAutoClear(autoClear) {
      (0, _object.set)(globals, 'autoClear', autoClear);
    },
    setDefaultClearDuration: function setDefaultClearDuration(clearDuration) {
      (0, _object.set)(globals, 'clearDuration', clearDuration);
    }
  });
});