define("ember-models-table/templates/components/models-table/themes/ember-paper/row-sorting-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "He5me0qh",
    "block": "[[[41,[30,0,[\"column\",\"componentForSortCell\"]],[[[44,[[50,[30,0,[\"column\",\"componentForSortCell\"]],0,null,[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\"],[[30,0,[\"column\"]],[30,0,[\"selectedItems\"]],[30,0,[\"expandedItems\"]],[30,0,[\"data\"]],[30,0,[\"themeInstance\"]],[30,0,[\"expandAllRows\"]],[30,0,[\"toggleAllSelection\"]],[30,0,[\"collapseAllRows\"]]]]]],[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,1],null,null,null],[1,\"\\n\"]],[]]]],[1]]]],[]],[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"column\",\"title\"]]],[1,\"\\n\"],[41,[30,0,[\"column\",\"useSorting\"]],[[[41,[28,[37,5],[[30,0,[\"column\",\"sortAsc\"]],[30,0,[\"column\",\"sortDesc\"]]],null],[[[1,\"        \"],[8,[39,6],[[16,0,[52,[30,0,[\"column\",\"sortAsc\"]],[30,0,[\"themeInstance\",\"sortAscIcon\"]],[52,[30,0,[\"column\",\"sortDesc\"]],[30,0,[\"themeInstance\",\"sortDescIcon\"]]]]]],[[\"@icon\"],[[52,[30,0,[\"column\",\"sortAsc\"]],[30,0,[\"themeInstance\",\"sortAscIcon\"]],[52,[30,0,[\"column\",\"sortDesc\"]],[30,0,[\"themeInstance\",\"sortDescIcon\"]]]]]],null],[1,\"\\n\"]],[]],null]],[]],null]],[]]]],[]]]],[\"CellContent\",\"&default\"],false,[\"if\",\"let\",\"component\",\"has-block\",\"yield\",\"or\",\"paper-icon\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-sorting-cell.hbs",
    "isStrictMode": false
  });
});