define("ember-cp-validations/utils/utils", ["exports", "@ember/array/proxy", "@ember/object/proxy", "@ember/template", "@ember/object", "@ember/utils", "@ember/array", "ember", "ember-require-module", "ember-cp-validations/-private/ember-internals"], function (_exports, _proxy, _proxy2, _template, _object, _utils, _array, _ember, _emberRequireModule, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "getDependentKeys", {
    enumerable: true,
    get: function get() {
      return _emberInternals.getDependentKeys;
    }
  });
  _exports.getValidatableValue = getValidatableValue;
  _exports.isDSManyArray = isDSManyArray;
  Object.defineProperty(_exports, "isDescriptor", {
    enumerable: true,
    get: function get() {
      return _emberInternals.isDescriptor;
    }
  });
  _exports.isDsModel = isDsModel;
  _exports.isEmberObject = isEmberObject;
  _exports.isObject = isObject;
  _exports.isPromise = isPromise;
  _exports.isProxy = isProxy;
  _exports.isValidatable = isValidatable;
  _exports.mergeOptions = mergeOptions;
  _exports.unwrapProxy = unwrapProxy;
  _exports.unwrapString = unwrapString;
  var DS = (0, _emberRequireModule.default)('ember-data');
  var canInvoke = _ember.default.canInvoke;
  function unwrapString(s) {
    if ((0, _template.isHTMLSafe)(s)) {
      return s.toString();
    }
    return s;
  }
  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy((0, _object.get)(o, 'content')) : o;
  }
  function isProxy(o) {
    return !!(o && (o instanceof _proxy2.default || o instanceof _proxy.default));
  }
  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }
  function isDsModel(o) {
    return !!(DS && o && o instanceof DS.Model);
  }
  function isDSManyArray(o) {
    return !!(DS && o && (0, _array.isArray)(o) && (o instanceof DS.PromiseManyArray || o instanceof DS.ManyArray));
  }
  function isEmberObject(o) {
    return !!(o && o instanceof _object.default);
  }
  function isObject(o) {
    return (0, _utils.typeOf)(o) === 'object' || (0, _utils.typeOf)(o) === 'instance';
  }
  function isValidatable(value) {
    var v = unwrapProxy(value);
    return isDsModel(v) ? !(0, _object.get)(v, 'isDeleted') : true;
  }
  function getValidatableValue(value) {
    if (!value) {
      return value;
    }
    if (isDSManyArray(value)) {
      return (0, _array.A)(value.filter(function (v) {
        return isValidatable(v);
      }));
    }
    return isValidatable(value) ? value : undefined;
  }
  function mergeOptions() {
    var o = {};
    for (var i = arguments.length - 1; i >= 0; i--) {
      var _o = i < 0 || arguments.length <= i ? undefined : arguments[i];
      Object.assign(o, isObject(_o) ? _o : {});
    }
    return o;
  }
});