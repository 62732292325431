define("ember-models-table/utils/macros", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shownColumns = shownColumns;
  /*
   * @param {string} colspanKey
   * @return Ember.computed
   */
  function shownColumns(colspanKey) {
    return (0, _object.computed)("processedColumns.@each.{isVisible,".concat(colspanKey, "}"), 'processedColumns', function () {
      var skipCount = 0;
      return this.processedColumns.filter(function (c, index, columns) {
        var colspan = (0, _object.get)(c, colspanKey);
        var isVisible = (0, _object.get)(c, 'isVisible');
        var nextHiddenCells = columns.slice(index + 1, index + colspan).filter(function (c) {
          return (0, _object.get)(c, 'isHidden');
        });
        if ((0, _object.get)(nextHiddenCells, 'length') === colspan - 1 && !isVisible && colspan !== 1) {
          return false;
        }
        if (skipCount) {
          skipCount--;
          return false;
        }
        if (colspan === 1) {
          return isVisible;
        }
        if (colspan > 1) {
          skipCount = colspan - 1;
        }
        return true;
      });
    }).readOnly();
  }
});