define("ember-models-table/templates/components/models-table/row-grouping", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "aENK+TXj",
    "block": "[[[10,\"td\"],[15,0,[30,0,[\"themeInstance\",\"groupingCell\"]]],[15,\"colspan\",[30,0,[\"cellColspan\"]]],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"RowGroupToggle\"],[[50,[52,[30,0,[\"groupingRowComponent\"]],[30,0,[\"groupingRowComponent\"]],[30,0,[\"themeInstance\",\"rowGroupToggleComponent\"]]],0,null,[[\"groupedValue\",\"groupedLength\",\"currentGroupingPropertyName\",\"displayGroupedValueAs\",\"visibleProcessedColumns\",\"groupedItems\",\"selectedItems\",\"expandedItems\",\"visibleGroupedItems\",\"selectedGroupedItems\",\"expandedGroupedItems\",\"groupIsCollapsed\",\"themeInstance\",\"toggleGroupedRowsSelection\",\"toggleGroupedRowsExpands\",\"toggleGroupedRows\"],[[30,0,[\"groupedValue\"]],[30,0,[\"groupedLength\"]],[30,0,[\"currentGroupingPropertyName\"]],[30,0,[\"displayGroupedValueAs\"]],[30,0,[\"visibleProcessedColumns\"]],[30,0,[\"groupedItems\"]],[30,0,[\"selectedItems\"]],[30,0,[\"expandedItems\"]],[30,0,[\"visibleGroupedItems\"]],[30,0,[\"selectedGroupedItems\"]],[30,0,[\"expandedGroupedItems\"]],[30,0,[\"groupIsCollapsed\"]],[30,0,[\"themeInstance\"]],[30,0,[\"toggleGroupedRowsSelection\"]],[30,0,[\"toggleGroupedRowsExpands\"]],[30,0,[\"toggleGroupedRows\"]]]]]]]]],[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,1,[\"RowGroupToggle\"]],null,null,null],[1,\"\\n\"]],[]]]],[1]]],[13]],[\"RowGrouping\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-grouping.hbs",
    "isStrictMode": false
  });
});