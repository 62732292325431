define("ember-models-table/templates/components/models-table/page-size-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "pK7gPxRW",
    "block": "[[[44,[[28,[37,1],null,[[\"Select\"],[[50,[30,0,[\"themeInstance\",\"selectComponent\"]],0,null,[[\"id\",\"options\",\"value\",\"type\",\"themeInstance\",\"class\"],[[30,0,[\"inputId\"]],[30,0,[\"pageSizeOptions\"]],[30,0,[\"pageSize\"]],[30,0,[\"type\"]],[30,0,[\"themeInstance\"]],\"changePageSize\"]]]]]]],[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"form\"],[15,0,[30,0,[\"themeInstance\",\"form\"]]],[12],[1,\"\\n      \"],[10,0],[15,0,[30,0,[\"themeInstance\",\"formElementWrapper\"]]],[12],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,0,[\"themeInstance\",\"rowsCountMsg\"]]],[13],[1,\"\\n        \"],[8,[30,1,[\"Select\"]],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[1]]]],[\"PageSizeSelect\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/page-size-select.hbs",
    "isStrictMode": false
  });
});