define("ember-models-table/templates/components/models-table/row-expand", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "edRYlv5D",
    "block": "[[[44,[[50,[30,0,[\"expandedRowComponent\"]],0,null,[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[30,0,[\"record\"]],[30,0,[\"visibleProcessedColumns\"]],[30,0,[\"index\"]],[30,0,[\"clickOnRow\"]],[30,0,[\"themeInstance\"]]]]]],[[[1,\"  \"],[10,\"td\"],[15,\"colspan\",[30,0,[\"cellColspan\"]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,1],null,null,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]],[\"RowExpand\",\"&default\"],false,[\"let\",\"component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-expand.hbs",
    "isStrictMode": false
  });
});