function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("pix-ui/stories/pix-icon-button.stories", ["exports", "@ember/template-factory", "@storybook/addon-actions"], function (_exports, _templateFactory, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withBackground = _exports.small = _exports.argTypes = _exports.Default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixIconButton
              @ariaLabel={{ariaLabel}}
              @icon={{icon}}
              @iconPrefix={{iconPrefix}}
              @triggerAction={{triggerAction}}
              @withBackground={{withBackground}}
              @size={{size}}
              />
          
      */
      {
        "id": "upZ7hkza",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@ariaLabel\",\"@icon\",\"@iconPrefix\",\"@triggerAction\",\"@withBackground\",\"@size\"],[[99,1,[\"@ariaLabel\"]],[99,2,[\"@icon\"]],[99,3,[\"@iconPrefix\"]],[99,4,[\"@triggerAction\"]],[99,5,[\"@withBackground\"]],[99,6,[\"@size\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-icon-button\",\"ariaLabel\",\"icon\",\"iconPrefix\",\"triggerAction\",\"withBackground\",\"size\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    ariaLabel: 'Action du bouton',
    icon: 'times',
    triggerAction: function triggerAction() {
      return new Promise().resolves();
    }
  };
  var small = _exports.small = Template.bind({});
  small.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    size: 'small'
  });
  var withBackground = _exports.withBackground = Template.bind({});
  withBackground.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    withBackground: true
  });
  var argTypes = _exports.argTypes = {
    ariaLabel: {
      name: 'ariaLabel',
      description: 'l\'action du bouton, pour l\'accessibilité',
      type: {
        name: 'string',
        required: true
      },
      table: {
        defaultValue: {
          summary: 'times'
        }
      }
    },
    icon: {
      name: 'icon',
      description: 'Icône font-awesome',
      type: {
        name: 'string',
        required: true
      },
      table: {
        defaultValue: {
          summary: 'times'
        }
      }
    },
    iconPrefix: {
      name: 'iconPrefix',
      description: 'Prefix de l\'icône font-awesome',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['far', 'fas']
      }
    },
    triggerAction: {
      name: 'triggerAction',
      description: 'Fonction à appeler au clic du bouton',
      type: {
        required: true
      },
      defaultValue: (0, _addonActions.action)('triggerAction')
    },
    withBackground: {
      name: 'withBackground',
      description: 'Affichage du fond grisé',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    size: {
      name: 'size',
      description: 'Size: `small`, `big`',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['big', 'small']
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    color: {
      name: 'color',
      description: ' ⚠️ **Propriété dépréciée** ⚠️ Color: `light-grey`, `dark-grey`',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['light-grey', 'dark-grey']
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'light-grey'
        }
      }
    }
  };
});