define("pix-ui/stories/pix-tag.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tag = _exports.compactTag = _exports.argTypes = void 0;
  var tag = _exports.tag = function tag(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixTag @color={{color}} @compact={{compact}}>
              Contenu du tag
            </PixTag>
          
      */
      {
        "id": "5PP5L+iR",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@color\",\"@compact\"],[[99,1,[\"@color\"]],[99,2,[\"@compact\"]]]],[[\"default\"],[[[[1,\"\\n        Contenu du tag\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tag\",\"color\",\"compact\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var compactTag = _exports.compactTag = function compactTag(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixTag @color={{color}} @compact={{compact}}>
              Contenu du tag
            </PixTag>
          
      */
      {
        "id": "5PP5L+iR",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@color\",\"@compact\"],[[99,1,[\"@color\"]],[99,2,[\"@compact\"]]]],[[\"default\"],[[[[1,\"\\n        Contenu du tag\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tag\",\"color\",\"compact\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  compactTag.args = {
    compact: true
  };
  var argTypes = _exports.argTypes = {
    color: {
      name: 'color',
      description: 'Couleur du tag',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 'blue',
      control: {
        type: 'select',
        options: ['blue', 'blue-light', 'purple', 'purple-light', 'green', 'green-light', 'yellow', 'yellow-light', 'grey', 'grey-light']
      }
    },
    compact: {
      name: 'compact',
      description: 'Tag compact ou non',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false
    }
  };
});