define("ember-models-table/templates/components/models-table/cell-content-display", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sWaecasm",
    "block": "[[[1,[28,[35,0],[[30,0,[\"record\"]],[30,0,[\"column\",\"propertyName\"]]],null]],[1,\"\\n\"]],[],false,[\"get\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/cell-content-display.hbs",
    "isStrictMode": false
  });
});