define("ember-models-table/templates/components/models-table/themes/plain-html/global-filter", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LgJxMXUH",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"globalSearch\"],[12],[1,\"\\n\"],[41,[30,0,[\"themeInstance\",\"searchLabelMsg\"]],[[[1,\"      \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,0,[\"themeInstance\",\"searchLabelMsg\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,3],[[16,1,[30,0,[\"inputId\"]]],[16,0,[28,[37,4],[[30,0,[\"themeInstance\",\"input\"]],\" filterString\"],null]]],[[\"@value\",\"@placeholder\",\"@oninput\"],[[30,0,[\"value\"]],[30,0,[\"themeInstance\",\"searchPlaceholderMsg\"]],[30,0,[\"updateGlobalFilterString\"]]]],null],[1,\"\\n    \"],[10,\"button\"],[14,0,\"clearFilterIcon\"],[15,\"disabled\",[52,[51,[30,0,[\"globalFilterUsed\"]]],\"disabled\"]],[15,\"onClick\",[28,[37,6],[[30,0,[\"clearGlobalFilter\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"clearFilterIcon\"]]],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,0,[\"themeInstance\",\"clearGlobalFilterMsg\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"concat\",\"unless\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/plain-html/global-filter.hbs",
    "isStrictMode": false
  });
});