define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/global-filter", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ltDq5mUp",
    "block": "[[[41,[48,[30,3]],[[[1,\"  \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],[[24,0,\"globalSearch\"]],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"inline\",[30,0],[30,0,[\"noop\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"element\"]],[[24,0,\"input-group\"]],[[\"@property\",\"@type\"],[\"value\",\"text\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"themeInstance\",\"searchLabelMsg\"]],[[[1,\"        \"],[10,\"label\"],[15,\"for\",[30,2,[\"id\"]]],[14,0,\"input-group-addon\"],[12],[1,[30,0,[\"themeInstance\",\"searchLabelMsg\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[30,2,[\"control\"]],[[16,\"placeholder\",[30,0,[\"themeInstance\",\"searchPlaceholderMsg\"]]],[24,0,\"filterString\"]],null,null],[1,\"\\n      \"],[10,1],[14,0,\"input-group-btn\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"clearFilterIcon\"],[16,\"disabled\",[52,[51,[30,0,[\"globalFilterUsed\"]]],\"disabled\"]]],[[\"@type\",\"@onClick\"],[\"secondary\",[28,[37,6],[[30,0,[\"clearGlobalFilter\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \\n          \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"clearFilterIcon\"]]],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,0,[\"themeInstance\",\"clearGlobalFilterMsg\"]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]]]],[\"Form\",\"GlobalFilter\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"bs-form\",\"bs-button\",\"unless\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/global-filter.hbs",
    "isStrictMode": false
  });
});