define("ember-models-table/templates/components/models-table/summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "O/GXbRWu",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"form\"],[15,0,[30,0,[\"themeInstance\",\"form\"]]],[12],[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"themeInstance\",\"formElementWrapper\"]]],[12],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"summary\"]]],[1,\"\\n        \"],[10,3],[14,6,\"#\"],[14,\"role\",\"button\"],[15,0,[28,[37,4],[\"clearFilters \",[52,[51,[30,0,[\"anyFilterUsed\"]]],\"hidden\"]],null]],[15,\"onclick\",[28,[37,6],[[30,0,[\"doClearFilters\"]]],null]],[12],[1,\"\\n          \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[1,\"      \"],[10,\"input\"],[15,1,[30,0,[\"inputId\"]]],[15,0,[28,[37,4],[[30,0,[\"themeInstance\",\"input\"]],\" invisible\"],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"concat\",\"unless\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/summary.hbs",
    "isStrictMode": false
  });
});