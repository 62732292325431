define("pix-ui/stories/pix-banner.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withInternalLink = _exports.withExternalLink = _exports.warning = _exports.error = _exports.communicationPixOrga = _exports.communicationPixCertif = _exports.communicationPixApp = _exports.argsTypes = _exports.Default = void 0;
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixBanner
              @type={{type}}
              @actionLabel={{actionLabel}}
              @actionUrl={{actionUrl}}
            >
              Parcours de rentrée 2020 : les codes sont disponibles dans l'onglet campagne. N’oubliez pas de les diffuser aux élèves avant la Toussaint.
            </PixBanner>
          
      */
      {
        "id": "7ujnYMfD",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@type\",\"@actionLabel\",\"@actionUrl\"],[[99,1,[\"@type\"]],[99,2,[\"@actionLabel\"]],[99,3,[\"@actionUrl\"]]]],[[\"default\"],[[[[1,\"\\n        Parcours de rentrée 2020 : les codes sont disponibles dans l'onglet campagne. N’oubliez pas de les diffuser aux élèves avant la Toussaint.\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-banner\",\"type\",\"actionLabel\",\"actionUrl\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  var warning = _exports.warning = Template.bind({});
  warning.args = {
    type: 'warning'
  };
  var error = _exports.error = Template.bind({});
  error.args = {
    type: 'error'
  };
  var communicationPixApp = _exports.communicationPixApp = Template.bind({});
  communicationPixApp.args = {
    type: 'communication'
  };
  var communicationPixOrga = _exports.communicationPixOrga = Template.bind({});
  communicationPixOrga.args = {
    type: 'communication-orga'
  };
  var communicationPixCertif = _exports.communicationPixCertif = Template.bind({});
  communicationPixCertif.args = {
    type: 'communication-certif'
  };
  var withExternalLink = _exports.withExternalLink = Template.bind({});
  withExternalLink.args = {
    type: 'info',
    actionLabel: 'Voir le nouveau site',
    actionUrl: 'www.test.fr/'
  };
  var withInternalLink = _exports.withInternalLink = Template.bind({});
  withInternalLink.args = {
    type: 'info',
    actionLabel: 'Voir la liste des participants',
    actionUrl: 'campaign.list'
  };
  var argsTypes = _exports.argsTypes = {
    actionLabel: {
      name: 'actionLabel',
      description: 'Nom de l‘action',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: ''
    },
    actionUrl: {
      name: 'actionUrl',
      description: 'Lien de l‘action',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: ''
    },
    type: {
      name: 'type',
      description: 'Définit le type de bannière',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: {
        summary: 'information'
      },
      control: {
        type: 'select',
        options: ['information', 'warning', 'error', 'communication', 'communication-orga', 'communication-certif']
      }
    }
  };
});