define("pix-ui/stories/pix-collapsible.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multipleCollapsible = _exports.collapsible = _exports.argTypes = void 0;
  var collapsible = _exports.collapsible = function collapsible(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
          <PixCollapsible
            @title={{title}}
            @titleIcon={{titleIcon}}>
            <div>Contenu du PixCollapsible</div>
          </PixCollapsible>
          
      */
      {
        "id": "E8Q+wDUJ",
        "block": "[[[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[[99,1,[\"@title\"]],[99,2,[\"@titleIcon\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[12],[1,\"Contenu du PixCollapsible\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-collapsible\",\"title\",\"titleIcon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var multipleCollapsible = _exports.multipleCollapsible = function multipleCollapsible(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
          <div>
            <PixCollapsible
              @title="Titre A"
              @titleIcon={{titleIcon}}>
                <div>Contenu A</div>
            </PixCollapsible>
      
            <PixCollapsible
              @title="Titre B"
              @titleIcon={{titleIcon}}>
                <div>Contenu B</div>
            </PixCollapsible>
      
            <PixCollapsible
              @title="Titre C"
              @titleIcon={{titleIcon}}>
                <div>Contenu C</div>
            </PixCollapsible>
          </div>
          
      */
      {
        "id": "Sre1crmq",
        "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[\"Titre A\",[99,1,[\"@titleIcon\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,\"Contenu A\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[\"Titre B\",[99,1,[\"@titleIcon\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,\"Contenu B\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[\"Titre C\",[99,1,[\"@titleIcon\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,\"Contenu C\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"]],[],false,[\"pix-collapsible\",\"titleIcon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var argTypes = _exports.argTypes = {
    title: {
      name: 'title',
      description: 'Intitulé du contenu du PixCollapsible',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Titre du contenu à dérouler en cliquant'
    },
    titleIcon: {
      name: 'titleIcon',
      description: 'Ajoute l\'icône donnée en paramètre avant le titre du PixCollapsible',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'user'
    }
  };
});