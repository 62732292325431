define("pix-ui/stories/pix-textarea.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.textarea = _exports.argTypes = void 0;
  var textarea = _exports.textarea = function textarea(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixTextarea 
              @id={{id}}
              @value={{value}}
              @maxlength={{maxlength}}
              @label={{label}}
              @errorMessage={{errorMessage}}
            />
          
      */
      {
        "id": "F38+K/7/",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@value\",\"@maxlength\",\"@label\",\"@errorMessage\"],[[99,1,[\"@id\"]],[99,2,[\"@value\"]],[99,3,[\"@maxlength\"]],[99,4,[\"@label\"]],[99,5,[\"@errorMessage\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-textarea\",\"id\",\"value\",\"maxlength\",\"label\",\"errorMessage\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var argTypes = _exports.argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: ''
    },
    value: {
      name: 'value',
      description: 'Valeur du champ',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: ''
    },
    maxlength: {
      name: 'maxlength',
      description: 'Nombre de caractères maximal à taper dans le champ',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 500
    },
    label: {
      name: 'label',
      description: 'Donne un label au champ.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    errorMessage: {
      name: 'errorMessage',
      description: 'Affiche une erreur en dessous du champ.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    }
  };
});