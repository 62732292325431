define("ember-models-table/templates/components/models-table/themes/ember-paper/pagination-numeric", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "m3V09/MB",
    "block": "[[[44,[[28,[37,1],null,[[\"PageNumberSelect\",\"visiblePageNumbers\"],[[50,[30,0,[\"themeInstance\",\"selectComponent\"]],0,null,[[\"id\",\"label\",\"options\",\"value\",\"type\",\"themeInstance\"],[[30,0,[\"inputId\"]],[30,0,[\"themeInstance\",\"currentPageNumberMsg\"]],[30,0,[\"currentPageNumberOptions\"]],[30,0,[\"currentPageNumber\"]],\"number\",[30,0,[\"themeInstance\"]]]]],[30,0,[\"visiblePageNumbers\"]]]]]],[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"themeInstance\",\"paginationInternalWrapper\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"showCurrentPageNumberSelect\"]],[[[1,\"        \"],[8,[30,1,[\"PageNumberSelect\"]],null,null,null],[1,\" \\n\"]],[]],null],[1,\"      \"],[10,0],[15,0,[30,0,[\"themeInstance\",\"paginationBlock\"]]],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"visiblePageNumbers\"]]],null]],null],null,[[[41,[30,2,[\"isLink\"]],[[[1,\"            \"],[8,[39,8],[[16,0,[30,0,[\"themeInstance\",\"buttonDefault\"]]]],[[\"@onClick\"],[[28,[37,9],[[30,0,[\"gotoCustomPage\"]],[30,2,[\"label\"]]],null]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2,[\"label\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,8],[[16,0,[30,0,[\"themeInstance\",\"buttonDefault\"]]]],[[\"@disabled\"],[true]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2,[\"label\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]]]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[1]]]],[\"Pagination\",\"page\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"paper-button\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/pagination-numeric.hbs",
    "isStrictMode": false
  });
});