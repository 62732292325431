define("ember-modal-dialog/components/modal-dialog", ["exports", "@ember/object/computed", "@ember/component", "@ember/string", "@ember/object", "@ember/utils", "@ember/service", "ember-modal-dialog/templates/components/modal-dialog", "@ember/debug"], function (_exports, _computed, _component, _string, _object, _utils, _service, _modalDialog, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _modalDialog.default,
    modalService: (0, _service.inject)('modal-dialog'),
    destinationElementId: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('destinationElementId')) {
        this.set('destinationElementId', this.get('modalService.destinationElementId'));
      }
    },
    modalDialogComponentName: (0, _object.computed)('renderInPlace', 'tetherTarget', 'animatable', 'hasLiquidWormhole', 'hasLiquidTether', function () {
      var tetherTarget = this.get('tetherTarget');
      var hasLiquidTether = this.get('hasLiquidTether');
      var hasLiquidWormhole = this.get('hasLiquidWormhole');
      var animatable = this.get('animatable');
      if (this.get('renderInPlace')) {
        return 'ember-modal-dialog/-in-place-dialog';
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        return 'ember-modal-dialog/-liquid-tether-dialog';
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        return 'ember-modal-dialog/-tether-dialog';
      } else if (hasLiquidWormhole && animatable === true) {
        return 'ember-modal-dialog/-liquid-dialog';
      }
      return 'ember-modal-dialog/-basic-dialog';
    }),
    animatable: null,
    hasLiquidWormhole: (0, _computed.readOnly)('modalService.hasLiquidWormhole'),
    hasLiquidTether: (0, _computed.readOnly)('modalService.hasLiquidTether'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (false /* DEBUG */) {
        this.validateProps();
      }
    },
    validateProps: function validateProps() {
      var overlayPosition = this.get('overlayPosition');
      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (false && (0, _debug.warn)("overlayPosition value '".concat(overlayPosition, "' is not valid (valid values [").concat(VALID_OVERLAY_POSITIONS.join(', '), "])"), false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
    },
    // onClose - set this from templates

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */

    // containerClass - set this from templates
    containerClassNames: ['ember-modal-dialog'],
    // set this in a subclass definition

    // overlayClass - set this from templates
    overlayClassNames: ['ember-modal-overlay'],
    // set this in a subclass definition

    // wrapperClass - set this from templates
    wrapperClassNames: ['ember-modal-wrapper'],
    // set this in a subclass definition

    concatenatedProperties: ['containerClassNames', 'overlayClassNames', 'wrapperClassNames'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    hasOverlay: true,
    translucentOverlay: false,
    overlayPosition: 'parent',
    // `parent` or `sibling`
    clickOutsideToClose: false,
    renderInPlace: false,
    tetherTarget: null,
    stack: (0, _computed.oneWay)('elementId'),
    // pass a `stack` string to set a "stack" to be passed to liquid-wormhole / liquid-tether
    value: 0,
    // pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether

    targetAttachment: 'middle center',
    tetherClassPrefix: null,
    attachmentClass: (0, _object.computed)('attachment', function () {
      var attachment = this.get('attachment');
      if ((0, _utils.isEmpty)(attachment)) {
        return;
      }
      return attachment.split(' ').map(function (attachmentPart) {
        return "emd-attachment-".concat((0, _string.dasherize)(attachmentPart));
      }).join(' ');
    }),
    targetAttachmentClass: (0, _object.computed)('targetAttachment', function () {
      var targetAttachment = this.get('targetAttachment') || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return "ember-modal-dialog-target-attachment-".concat((0, _string.dasherize)(targetAttachment), " emd-target-attachment-").concat((0, _string.dasherize)(targetAttachment));
    }),
    ensureEmberTetherPresent: function ensureEmberTetherPresent() {
      if (!this.get('modalService.hasEmberTether')) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    },
    actions: {
      onClose: function onClose() {
        var onClose = this.get('onClose');
        // we shouldn't warn if the callback is not provided at all
        if ((0, _utils.isNone)(onClose)) {
          return;
        }
        (false && !((0, _utils.typeOf)(onClose) === 'function') && (0, _debug.assert)('onClose handler must be a function', (0, _utils.typeOf)(onClose) === 'function'));
        onClose();
      },
      onClickOverlay: function onClickOverlay(e) {
        e.preventDefault();
        var onClickOverlay = this.get('onClickOverlay');
        // we shouldn't warn if the callback is not provided at all
        if ((0, _utils.isNone)(onClickOverlay)) {
          this.send('onClose');
          return;
        }
        (false && !((0, _utils.typeOf)(onClickOverlay) === 'function') && (0, _debug.assert)('onClickOverlay handler must be a function', (0, _utils.typeOf)(onClickOverlay) === 'function'));
        onClickOverlay();
      }
    }
  });
});