define("ember-models-table/templates/components/models-table/themes/ember-paper/cell-edit-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "d6XBJnoY",
    "block": "[[[41,[30,0,[\"isEditRow\"]],[[[1,\"  \"],[8,[39,1],[[16,0,[28,[37,2],[[30,0,[\"themeInstance\",\"buttonDefault\"]],\" \",[30,0,[\"themeInstance\",\"cancelRowButton\"]]],null]]],[[\"@raised\",\"@onClick\"],[true,[30,0,[\"cancelClicked\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"cancelButtonLabel\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[16,0,[28,[37,2],[[30,0,[\"themeInstance\",\"buttonDefault\"]],\" \",[30,0,[\"themeInstance\",\"saveRowButton\"]]],null]]],[[\"@raised\",\"@onClick\"],[true,[30,0,[\"saveClicked\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"saveButtonLabel\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],[[16,0,[28,[37,2],[[30,0,[\"themeInstance\",\"buttonDefault\"]],\" \",[30,0,[\"themeInstance\",\"editRowButton\"]]],null]]],[[\"@raised\",\"@onClick\"],[true,[30,0,[\"editClicked\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"editButtonLabel\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]],[18,1,null]],[\"&default\"],false,[\"if\",\"paper-button\",\"concat\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/cell-edit-toggle.hbs",
    "isStrictMode": false
  });
});