define("ember-models-table/templates/components/models-table/grouped-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "jLhWBFdx",
    "block": "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,[[28,[37,3],null,[[\"groupedHeader\",\"shouldAddExtraColumn\"],[[30,0,[\"groupedHeader\"]],[30,0,[\"shouldAddExtraColumn\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"    \"],[10,\"th\"],[12],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"groupedHeader\"]]],null]],null],null,[[[1,\"    \"],[10,\"th\"],[15,\"colspan\",[30,1,[\"colspan\"]]],[15,\"rowspan\",[30,1,[\"rowspan\"]]],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[1]],null]],[]]]],[\"cell\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/grouped-header.hbs",
    "isStrictMode": false
  });
});