define("ember-models-table/templates/components/models-table/row-sorting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "YAxBRZh7",
    "block": "[[[44,[[28,[37,1],null,[[\"shouldAddExtraColumn\",\"RowSortingCell\"],[[30,0,[\"shouldAddExtraColumn\"]],[50,[30,0,[\"themeInstance\",\"rowSortingCellComponent\"]],0,null,[[\"themeInstance\",\"selectedItems\",\"expandedItems\",\"sort\",\"data\",\"expandAllRows\",\"collapseAllRows\",\"toggleAllSelection\"],[[30,0,[\"themeInstance\"]],[30,0,[\"selectedItems\"]],[30,0,[\"expandedItems\"]],[30,0,[\"doSort\"]],[30,0,[\"data\"]],[30,0,[\"expandAllRows\"]],[30,0,[\"collapseAllRows\"]],[30,0,[\"toggleAllSelection\"]]]]]]]]],[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"      \"],[10,\"th\"],[12],[1,\"\\n\"],[41,[30,0,[\"groupHeaderCellComponent\"]],[[[1,\"          \"],[46,[30,0,[\"groupHeaderCellComponent\"]],null,[[\"currentGroupingPropertyName\"],[[30,0,[\"currentGroupingPropertyName\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,0,[\"currentGroupingPropertyNameTitlelized\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"shownColumns\"]]],null]],null],null,[[[1,\"      \"],[8,[30,1,[\"RowSortingCell\"]],null,[[\"@column\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null]],[]]]],[1]]]],[\"RowSorting\",\"column\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-sorting.hbs",
    "isStrictMode": false
  });
});