define("pix-ui/stories/pix-input-code.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.Template = _exports.Default = void 0;
  var Template = _exports.Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixInputCode
              @ariaLabel={{ariaLabel}}
              @inputType={{inputType}}
              @numInputs={{numInputs}}
            />
          
      */
      {
        "id": "Wo2AOQE9",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@ariaLabel\",\"@inputType\",\"@numInputs\"],[[99,1,[\"@ariaLabel\"]],[99,2,[\"@inputType\"]],[99,3,[\"@numInputs\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-input-code\",\"ariaLabel\",\"inputType\",\"numInputs\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    ariaLabel: "Code de validation d'adresse e-mail"
  };
  var argTypes = _exports.argTypes = {
    'ariaLabel': {
      name: 'ariaLabel',
      description: "L'aria-label de chaque champ",
      type: {
        name: 'string',
        required: true
      }
    },
    inputType: {
      name: 'inputType',
      description: "Le type de chaque champ : `number` ou `text`",
      type: {
        name: 'string',
        required: false
      },
      options: ['number', 'text'],
      control: {
        type: 'select'
      },
      table: {
        defaultValue: {
          summary: 'number'
        }
      }
    },
    numInputs: {
      name: 'numInputs',
      description: "Le nombre de champ",
      type: {
        name: 'number',
        required: false
      },
      table: {
        defaultValue: {
          summary: 6
        }
      }
    },
    onAllInputsFilled: {
      name: 'onAllInputsFilled',
      description: 'fonction appeler une fois que tous les champs ont été rempli avec le code en parametre',
      type: {
        required: false
      },
      control: {
        disable: true
      }
    }
  };
});