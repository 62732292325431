define("ember-models-table/templates/components/models-table/select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "VAs3Gokm",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"  \"],[10,\"option\"],[15,2,[30,1,[\"value\"]]],[15,\"selected\",[28,[37,2],[[30,0,[\"value\"]],[30,1,[\"value\"]],[30,0,[\"type\"]]],null]],[12],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[18,2,null],[1,\"\\n\"]],[\"opt\",\"&default\"],false,[\"each\",\"-track-array\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/select.hbs",
    "isStrictMode": false
  });
});