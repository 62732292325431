define("ember-models-table/templates/components/models-table/no-data", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "kniPUXt+",
    "block": "[[[10,\"td\"],[15,\"colspan\",[30,0,[\"realColumnsCount\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],[[30,0,[\"themeInstance\",\"noDataToShowMsg\"]]],null]],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"html-safe\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/no-data.hbs",
    "isStrictMode": false
  });
});