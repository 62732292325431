function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("pix-ui/stories/pix-radio-button.stories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDisabled = _exports.disabledChecked = _exports.defaultChecked = _exports.argTypes = _exports.Default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var Template = function Template(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixRadioButton
              @label={{label}}
              @value={{value}}
              @isDisabled={{isDisabled}}
            />
          
      */
      {
        "id": "KdVkJA88",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@label\",\"@value\",\"@isDisabled\"],[[99,1,[\"@label\"]],[99,2,[\"@value\"]],[99,3,[\"@isDisabled\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-radio-button\",\"label\",\"value\",\"isDisabled\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var Default = _exports.Default = Template.bind({});
  Default.args = {
    label: 'Poivron'
  };
  var isDisabled = _exports.isDisabled = Template.bind({});
  isDisabled.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    isDisabled: true
  });
  var checked = function checked(args) {
    return {
      template: (0, _templateFactory.createTemplateFactory)(
      /*
        
            <PixRadioButton
              @label={{label}}
              @isDisabled={{isDisabled}}
              checked
            />
          
      */
      {
        "id": "MmjgB5w8",
        "block": "[[[1,\"\\n      \"],[8,[39,0],[[24,\"checked\",\"\"]],[[\"@label\",\"@isDisabled\"],[[99,1,[\"@label\"]],[99,2,[\"@isDisabled\"]]]],null],[1,\"\\n    \"]],[],false,[\"pix-radio-button\",\"label\",\"isDisabled\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };
  var disabledChecked = _exports.disabledChecked = checked.bind({});
  disabledChecked.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    isDisabled: true
  });
  var defaultChecked = _exports.defaultChecked = checked.bind({});
  defaultChecked.args = Default.args;
  var argTypes = _exports.argTypes = {
    label: {
      name: 'label',
      description: 'Le label du bouton radio',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: 'Valeur permettant d\'identifier l\'option sélectionnée',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    isDisabled: {
      name: 'isDisabled',
      description: 'Pour désactiver/activer le bouton radio',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false,
      table: {
        defaultValue: {
          summary: 'false'
        }
      }
    }
  };
});