define("ember-models-table/templates/components/models-table/themes/plain-html/summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DzE8uFij",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"summary\"]]],[1,\"\\n  \"],[10,\"button\"],[15,0,[28,[37,4],[\"clearFilters \",[52,[51,[30,0,[\"anyFilterUsed\"]]],\"emt-sr-only\"]],null]],[15,\"onclick\",[28,[37,6],[[30,0,[\"doClearFilters\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"concat\",\"unless\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/plain-html/summary.hbs",
    "isStrictMode": false
  });
});