define("ember-models-table/templates/components/models-table/expand-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lPtvt7RY",
    "block": "[[[41,[30,0,[\"isExpanded\"]],[[[1,\"  \"],[10,\"button\"],[15,0,[28,[37,1],[[30,0,[\"themeInstance\",\"buttonLink\"]],\" \",[30,0,[\"themeInstance\",\"collapseRow\"]]],null]],[15,\"onclick\",[28,[37,2],[[30,0,[\"doCollapseRow\"]],[30,0,[\"index\"]],[30,0,[\"record\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"collapseRowIcon\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"button\"],[15,0,[28,[37,1],[[30,0,[\"themeInstance\",\"buttonLink\"]],\" \",[30,0,[\"themeInstance\",\"expandRow\"]]],null]],[15,\"onclick\",[28,[37,2],[[30,0,[\"doExpandRow\"]],[30,0,[\"index\"]],[30,0,[\"record\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"expandRowIcon\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[18,1,null]],[\"&default\"],false,[\"if\",\"concat\",\"fn\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/expand-toggle.hbs",
    "isStrictMode": false
  });
});